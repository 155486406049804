import './assets/app.css'

import { createApp } from 'vue'

import { createPinia } from 'pinia'
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as Sentry from '@sentry/vue'

import App from './App.vue'
import router from './router'

const app = createApp(App)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration()
    // Sentry.browserProfilingIntegration()
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/cms\.ashor\.live\/api/,
    /^https:\/\/ashor\.live/
  ],
  // Session Replay
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  // component trace
  trackComponents: true,
  hooks: ['activate', 'create', 'unmount', 'mount', 'update']
  // Profiling
  // profilesSampleRate: 1.0
})

const pinia = createPinia()
app.use(pinia)

app.use(router)

app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)

app.mount('#app')
