<template>
  <div
    class="container mx-auto bg-primary max-w-full min-h-screen flex flex-col items-center justify-center"
  >
    <LoadingRing :loading="ticketStore.loadingData" />
    <div v-if="ticketStore.ticketNotFound">
      <h1 class="antialiased text-secondary text-center text-2xl">Ticket did not match!</h1>
      <p class="antialiased text-secondary text-center text-3xl mt-6">
        <strong class="text-error">{{ ticketStore.ticketId }}</strong>
      </p>
    </div>
    <div v-if="!ticketStore.loadingData && !ticketStore.ticketNotFound">
      <div>
        <h2 class="antialiased text-secondary text-center text-xl">
          Record found for {{ ticketStore.ticketData?.name }}!
        </h2>
        <h1 class="antialiased text-secondary text-center text-2xl mt-6">Ticket Status</h1>
        <p class="antialiased text-secondary text-center text-4xl">
          <strong :class="ticketStore.statusStyle">{{ ticketStore.ticketStatus }}</strong>
        </p>
      </div>
      <div class="mt-3 mb-3">
        <TicketLoader :ticketData="ticketStore.ticketData" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useTicketStore } from '../stores/ticketStore'

import TicketLoader from '../components/TicketLoader.vue'
import LoadingRing from '../components/LoadingRing.vue'

const route = useRoute()
const ticketStore = useTicketStore()

onMounted(async () => {
  const ticketId = route.params.id
  await ticketStore.fetchTicketData(ticketId)
})
</script>
