import { createRouter, createWebHistory } from 'vue-router'
import TicketRegView from '../views/TicketRegView.vue'
import TicketPreview from '../views/TicketPreview.vue'
import TicketValidationView from '../views/TicketValidationView.vue'

import HomeView from '../views/HomeView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      // redirect: '/flood-aid/tickets/'
      name: 'onboard',
      component: HomeView
    },
    {
      path: '/flood-aid/tickets/',
      name: 'flood-aid-registration',
      component: TicketRegView
    },
    {
      path: '/flood-aid/tickets/:id/preview',
      name: 'flood-aid-ticket-preview',
      component: TicketPreview
    },
    {
      path: '/flood-aid/tickets/:id/validate',
      name: 'flood-aid-ticket-validation',
      component: TicketValidationView
    }
  ]
})

export default router
