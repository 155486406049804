<template>
  <div ref="ticket" class="ticket bg-white p-6 rounded-lg shadow-xl mt-6">
    <h2 class="text-center text-xl font-bold">Flood Aid Live</h2>
    <h2 class="text-center text-sm mb-4">Together We Rise</h2>
    <p class="text-center text-xl mt-2">{{ ticketData?.name }}</p>
    <p class="text-center text-center flex flex-row justify-center">
      <IconUsers class="mr-2" />{{ ticketData?.number_of_tickets }} person(s)
    </p>
    <p class="text-center flex flex-row items-center justify-center mt-2">
      <span class="text-xl mr-2">@</span>
      Jamuna Future Park
    </p>
    <p class="text-center flex flex-row justify-center"><IconClock class="mr-2" />2:30pm-10:00pm</p>
    <p class="text-center flex flex-row justify-center">
      <IconCalenderDays class="mr-2" />14th Sept. '24
    </p>
    <p class="text-center mt-2"><strong>Gates Open:</strong> 1:30pm</p>
    <div class="flex justify-center mt-4">
      <qrcode-vue :value="ticketQRLink" :size="qrSize" :color="qrColor"></qrcode-vue>
    </div>
    <p class="text-center mt-4 mb-6"><strong>Ticket ID:</strong> {{ ticketData?.ticket_id }}</p>
    <div class="flex flex-col items-center mb-2">
      <p class="text-center text-sm mt-2 mb-2">Powered By</p>
      <img class="w-10 h-10 rounded-full" :src="LogoAshorSquare" alt="ashor-logo" />
    </div>
  </div>
</template>

<style scoped>
.ticket {
  width: 350px;
}
</style>

<script setup>
import { computed } from 'vue'
import QrcodeVue from 'qrcode.vue'

import LogoAshorSquare from '@/assets/logo.png'
import IconCalenderDays from '@/components/icons/IconCalenderDays.vue'
import IconClock from '@/components/icons/IconClock.vue'
import IconUsers from '@/components/icons/IconUsers.vue'

const props = defineProps({
  ticketData: {
    type: Object,
    required: true
  }
})

const qrSize = 160
const qrColor = '#000000'

const domain = import.meta.env.VITE_APP_DOMAIN

const ticketQRLink = computed(() => {
  return `${domain}/flood-aid/tickets/${props.ticketData?.ticket_id}/validate`
})
</script>
