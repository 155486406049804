<template>
  <div
    class="container mx-auto bg-primary max-w-full min-h-screen flex flex-col items-center justify-center"
  >
    <div class="ashor-logo-long" :class="{ 'fade-in': showLogo }">
      <IconAshorLong />
    </div>
    <div v-if="showSubText" class="prose">
      <h1
        class="antialiased text-secondary text-center text-3xl md:text-3xl typing-text"
        :class="{ 'cursor-effect': isTyping }"
      >
        {{ displayedSubText }}
      </h1>
    </div>
    <div class="mt-3">
      <button
        class="btn md:btn-lg btn-outline btn-secondary mt-6 btn-redirect"
        :class="{ 'fade-in': showRedirectBtn }"
        @click="redirectToTicket"
      >
        Grab Tickets!
      </button>
    </div>
  </div>
</template>

<style scoped>
.ashor-logo-long {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.btn-redirect {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.typing-text {
  /* font-size: 32px; */
  /* font-weight: 500; */
  font-family: 'Courier New', Courier, monospace;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: normal;
}

.cursor-effect {
  border-right: 1px solid white; /* Cursor effect */
  padding-right: 2px; /* Space between text and cursor */
}
</style>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import IconAshorLong from './icons/IconAshorLong.vue'

const props = defineProps({
  subText: {
    type: String,
    required: true,
    default: 'Grab Your Tickets!'
  },
  redirectUrl: {
    type: String,
    required: false,
    default: null
  },
  routeRedirect: {
    type: Boolean,
    required: false,
    default: false
  }
})

const router = useRouter()

const showLogo = ref(false)
const showSubText = ref(false)
const showRedirectBtn = ref(false)

const displayedSubText = ref('')
const typingSpeed = 100 // Speed in milliseconds
const isTyping = ref(false)

onMounted(() => {
  setTimeout(() => {
    showLogo.value = true
  }, 1500)

  setTimeout(() => {
    isTyping.value = true
    showSubText.value = true
    typeSubText()
  }, 2000)

  setTimeout(
    () => {
      isTyping.value = false
      if (props.redirectUrl !== null) {
        window.location.href = props.redirectUrl
      }

      if (props.routeRedirect) {
        showRedirectBtn.value = true
      }
    },
    1500 + props.subText.length * typingSpeed + 1000
  )
})

function typeSubText() {
  let index = 0
  const interval = setInterval(() => {
    if (index < props.subText.length) {
      displayedSubText.value += props.subText.charAt(index)
      index++
    } else {
      clearInterval(interval)
    }
  }, typingSpeed)
}

function redirectToTicket() {
  router.push({
    name: 'flood-aid-registration'
  })
}
</script>
